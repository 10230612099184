import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Modal from "@material-ui/core/Modal"
import IconButton from "@material-ui/core/IconButton"
import ChevronRight from "@material-ui/icons/ChevronRight"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Close from "@material-ui/icons/Close"
import { useSwipeable } from "react-swipeable"

const useStyles = makeStyles(theme => {
  const gridMaxWidth = theme.breakpoints.values.md
  const gridSpacing = 5
  return {
    img: { height: "100%", width: "100%", flexGrow: 1 },
    gallery: {
      display: "grid",
      gridTemplateColumns: `repeat(3, 1fr)`,
      gridTemplateRows: `repeat(3, 1fr)`,
      gridGap: gridSpacing,
      marginTop: theme.spacing(18),
      width: "80vw",
      maxWidth: gridMaxWidth,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(8),
      },
    },
    modalImage: {
      width: "70vw",
      maxHeight: "80vh",
      outline: 0,
      gridArea: "image",
    },
    modal: {
      display: "grid",
      gridTemplateAreas: "'buttonLeft image buttonRight'",
      gridTemplateColumns: "min-content 1fr min-content",
      position: "relative",
      justifyItems: "center",
      alignItems: "center",
      outline: 0,
      padding: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        gridTemplateAreas: "'image image' 'buttonLeft buttonRight'",
        gridTemplateColumns: "1fr 1fr",
      },
    },
    buttonLeft: {
      gridArea: "buttonLeft",
    },
    buttonRight: {
      gridArea: "buttonRight",
    },
    closeButton: {
      position: "absolute",
      top: 0,
      right: 0,
    },
  }
})

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query Gallery {
      allFile(
        filter: { relativeDirectory: { eq: "gallery" } }
        sort: { order: DESC, fields: size }
      ) {
        edges {
          node {
            id
            thumbnail: childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                width
              }
            }
            large: childImageSharp {
              fluid(maxWidth: 2000, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
              original {
                width
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges
  const status = { open: "open", closed: "closed" }
  const [modal, setModalState] = useState({
    status: status.closed,
    image: null,
  })
  const classes = useStyles()
  const openModal = image => () =>
    setModalState({
      status: status.open,
      image,
    })
  const closeModal = () => setModalState({ status: status.closed, image: null })
  const nextModalImage = event => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation()
    }
    setModalState(prev => {
      const nextImage = prev.image < images.length - 1 ? prev.image + 1 : 0
      return { ...prev, image: nextImage }
    })
  }
  const prevModalImage = event => {
    if (event && typeof event.stopPropagation === "function") {
      event.stopPropagation()
    }
    setModalState(prev => {
      const nextImage = prev.image > 0 ? prev.image - 1 : images.length - 1
      return { ...prev, image: nextImage }
    })
  }
  const handleImageKeyDown = index => event => {
    event.preventDefault()
    if (["Enter", " "].includes(event.key)) {
      openModal(index)()
    }
  }
  const handleModalKeyDown = event => {
    event.preventDefault()
    if (event.key === "ArrowRight") {
      nextModalImage()
    }
    if (event.key === "ArrowLeft") {
      prevModalImage()
    }
  }
  const handlers = useSwipeable({
    onSwipedLeft: prevModalImage,
    onSwipedRight: nextModalImage,
  })

  console.log(data)
  return (
    <>
      <Box width={1} display="flex" alignItems="center" justifyContent="center">
        <Box className={classes.gallery}>
          {images.map(({ node: { thumbnail, id } }, index) => (
            <div
              key={id}
              onClick={openModal(index)}
              role="button"
              onKeyDown={handleImageKeyDown(index)}
              tabIndex="0"
            >
              <Img
                className={classes.img}
                fluid={{ ...thumbnail.fluid, aspectRatio: 1 }}
              ></Img>
            </div>
          ))}
        </Box>
      </Box>

      <Modal
        open={modal.status === status.open}
        onClose={closeModal}
        onKeyPress={handleModalKeyDown}
      >
        <Box
          height={1}
          width={1}
          onClick={closeModal}
          className={classes.modal}
          color="common.white"
          onKeyDown={handleModalKeyDown}
          tabIndex="0"
          onFocus={console.log}
          {...handlers}
        >
          <IconButton
            color="inherit"
            size="medium"
            onClick={closeModal}
            className={classes.closeButton}
          >
            <Close />
          </IconButton>
          <IconButton
            color="inherit"
            size="medium"
            onClick={prevModalImage}
            className={classes.buttonLeft}
          >
            <ChevronLeft />
          </IconButton>
          {images.map(({ node: { large, id } }, index) => (
            <Img
              fluid={large.fluid}
              className={classes.modalImage}
              key={id}
              style={{
                opacity: modal.image === index ? 1 : 0,
                transition: "opacity 350ms ease-in",
                maxWidth: large.original.width,
              }}
            />
          ))}
          <IconButton
            onClick={nextModalImage}
            color="inherit"
            size="medium"
            className={classes.buttonRight}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Modal>
    </>
  )
}

export default Gallery
