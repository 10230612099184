import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import { contentPaddingY } from "../config"
import Gallery from "../components/gallery"
import { makeStyles } from "@material-ui/core"
import Phone from "../components/phone"
import { Link } from "gatsby-theme-material-ui"

const useStyles = makeStyles(theme => ({
  subheading: { marginBottom: theme.spacing(8) },
}))
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "roof-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <Layout
      bgImage={data.bgImage.childImageSharp.fluid}
      backgroundPosition="top center"
    >
      <SEO title="Home" />
      <Box py={contentPaddingY}>
        <Typography variant="h2">Coastline Roofing Solutions</Typography>
        <Typography variant="h6" className={classes.subheading}>
          Supplying high quality roofing services to Wollongong and the South
          Coast
        </Typography>
        <Typography paragraph>
          Coastline Roofing is a family run roofing business and has been
          delivering high quality roofing services to Wollongong and the South
          Coast since 2009.
        </Typography>
        <Typography paragraph>
          We provide domestic and commercial roofing services for metal or tile
          roofs including: new roofs, roof repairs, regular roof and gutter
          maintence, upgrades to existing roofing such as insulation,
          ventilation and leaf guard.
        </Typography>
        <Typography paragraph>
          Our teams are well trained and equipped to ensure they can work safely
          at height. We also have safety procedures in place to manage
          infections risks during COVID-19.
        </Typography>
        <Typography paragraph>
          Get in touch today to find out more. Fill out a{" "}
          <Link to="/contact-us">contact form</Link> or call Jono directly on{" "}
          <Phone />.
        </Typography>
        <Gallery />
      </Box>
    </Layout>
  )
}

export default IndexPage
